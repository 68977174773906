import React, {useState} from 'react';
import {Icon, Menu, Dropdown} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';
import { privacyPolicyLink, termsLink, copyrights } from '../consts';
import Wizard from '../Wizard/Wizard';

const MenuItem = (props) => {
  const {name, icon, label, to} = props;
  return (
    <Menu.Item key={name} name={name} as='h3' style={{paddingLeft: 10, paddingBottom : 7}} className="sidebar-link">
    <Icon name={icon} style={{float : 'none', marginRight : '0.9rem', fontSize: '0.8em', color: 'rgb(0,0,0, 0.7)'}}/>
    <NavLink exact to={to} className="link" style={{fontSize : '0.9em'}} activeClassName="active">{label} </NavLink>
  </Menu.Item>
  )
}

const ComputerMenu = () => {
  const [showWizard, setShowWizard] = useState(false);

    return (
      <div style={{paddingBottom : 35}}>      
        <Menu secondary fluid vertical>
          <MenuItem name='dashboard' icon='home' to='/' label='Dashboard'/>
        </Menu>      
      <h2 className="sidebar-title">Reports</h2>
        <Menu secondary fluid vertical>
          <MenuItem name='application' icon='window restore outline' to='/applications' label='Applications'/>
          <MenuItem name='webistes' icon='globe' to='/websites' label='Websites'/>
          <MenuItem name='computertime' icon='time' to='/computer_time' label='Computer Time'/>
          <MenuItem name='searchqueries' icon='search' to='/search_queries' label='Search Queries'/>          
          <MenuItem name='alerts' icon='warning' to='/alerts' label='Alerts'/>          
        </Menu>
        <h2 className="sidebar-title">Rules</h2>
        <Menu secondary fluid vertical>                    
          <MenuItem name='websitefilter' icon='filter' to='/website_filter' label='Website Limits'/>          
          <MenuItem name='websiteblocking' icon='minus circle' to='/website_blocking' label='Website Blocking'/>                        
          <MenuItem name='applicationfilter' icon='lock' to='/application_filter' label='App Blocking'/>                   
          <MenuItem name='windowssecurity' icon='shield alternate' to='/windows_security' label='Enhanced Security'/>
          <MenuItem name='timecontrols' icon='history' to='/time_controls' label='Screen Time'/>                  
        </Menu>
        <h2 className="sidebar-title">Settings</h2>
        <Menu secondary fluid vertical>          
          <MenuItem name='generalsettings' icon='setting' to='/general_settings' label='General Settings'/>                
          <MenuItem name='usersettings' icon='user' to='/user_settings' label='User Settings'/>   
          <Menu.Item key='wizard' name='wizard' as='h3' style={{cursor: 'pointer',  paddingLeft: 10}} className="sidebar-link" onClick={() => setShowWizard(true)}>
            <Icon name='magic' style={{float : 'none', marginRight : '0.9rem', fontSize: '0.9em', color: 'rgb(0,0,0, 0.7)'}}/><span style={{fontSize : '0.9em'}} >Wizard</span>
          </Menu.Item>
        </Menu>
        <Wizard open={showWizard} handleClose={() => setShowWizard(false)}/> 
        <div>
          <a style={{color : "#555"}} target="_blank" rel="noopener noreferrer" href={termsLink}>Terms</a>
          <a style={{marginLeft : 10, color : "#555"}} target="_blank" rel="noopener noreferrer" href={privacyPolicyLink}>Privacy Policy</a>
        </div>
        <div style={{marginBottom : 10, color : "#666"}}>{copyrights}</div>
      </div>    
    )
  }

  const CollapsedComputerMenu  = () => (   
    <div style={{zIndex: 10000, position: "fixed", marginTop: "5px", top: "5px", marginRight: 10, right: 15}}>
    <Dropdown       
      icon='bars'         
      button
      direction='left'      
      className='icon sidebar-icon'
    >
      <Dropdown.Menu style={{maxHeight: 'calc(100vh - 100px)', overflowY: 'auto'}}>
          <MenuItem name='dashboard' icon='home' to='/' label='Dashboard'/>
        <Dropdown.Header className="sidebar-title">Reports</Dropdown.Header>        
          <MenuItem name='application' icon='window restore outline' to='/applications' label='Applications'/>
          <MenuItem name='webistes' icon='globe' to='/websites' label='Websites'/>
          <MenuItem name='computertime' icon='time' to='/computer_time' label='Computer Time'/>
          <MenuItem name='searchqueries' icon='search' to='/search_queries' label='Search Queries'/>          
          <MenuItem name='alerts' icon='warning' to='/alerts' label='Alerts'/>          
        <Dropdown.Header>Rules</Dropdown.Header>        
          <MenuItem name='websitefilter' icon='filter' to='/website_filter' label='Website Limits'/>          
          <MenuItem name='websiteblocking' icon='minus circle' to='/website_blocking' label='Website Blocking'/>                        
          <MenuItem name='applicationfilter' icon='lock' to='/application_filter' label='App Blocking'/>                   
          <MenuItem name='windowssecurity' icon='shield alternate' to='/windows_security' label='Enhanced Security'/>
          <MenuItem name='timecontrols' icon='history' to='/time_controls' label='Screen Time'/>                  
        <Dropdown.Header>Settings</Dropdown.Header>        
          <MenuItem name='generalsettings' icon='setting' to='/general_settings' label='General Settings'/>                
          <MenuItem name='usersettings' icon='user' to='/user_settings' label='User Settings'/>                     
      </Dropdown.Menu>
    </Dropdown>

    </div> 

  )

  export {ComputerMenu, CollapsedComputerMenu};