import React, {useState, useEffect} from "react";
import firebase from '../../firebase';
import withUID from '../withUID';
import moment from 'moment';
import AppIcon from '../UI/AppIcon';
import WebIcon from '../UI/WebIcon';
import { secondsToStringSub } from "../../utils";

const timeLimit = (title, limit, mode, timeSpent) => {
    if (!timeSpent) return <></>;

    const mul = mode === 'hour' ? 30 : mode === 'day' ? 300 : 2100;
    const timeLimit = limit < 121 ? limit * mul : 'Unlimited'; 
    const timeLimitDisplay = timeLimit !== 'Unlimited' ? <>{secondsToStringSub(timeLimit)} <sub>per {mode}</sub></> : 'Unlimited';
    const percentage = timeLimit !== 'Unlimited' ? Math.min((timeSpent / timeLimit) * 100, 100) : 0;      

    return (
        <div style={{ marginTop: 10 }}>
            <div><span style={{marginRight: 20, color: '#666'}}>{title}</span> {secondsToStringSub(timeSpent)} / {timeLimitDisplay}</div>
            <div style={{marginTop: 5, marginLeft : 90, display: 'flex', alignItems: 'center' }}>
                <div style={{ fontSize: '12px', marginRight: '8px' }}>{Math.round(percentage)}%</div>
                <div style={{
                    width: '200px',
                    height: '16px',
                    backgroundColor: '#f2f2f2',
                    borderRadius: '5px',
                    overflow: 'hidden'
                }}>
                    <div style={{
                        width: `${percentage}%`,
                        height: '100%',
                        backgroundColor: '#FFDF40',
                        boxShadow: '1px 1px 1px 0 rgba(0,0,0,.1)',
                        borderRadius: '5px 0 0 5px',
                        transition: 'width 0.3s ease-in-out'
                    }}></div>
                </div>
            </div>
        </div>        
    )

}

const LastActivity = ({uid, currentComputer}) => {
    const [lastActivity, setLastActivity] = useState({});

    useEffect(() => {
        const ref = firebase.database().ref(`${uid}/${currentComputer}/reports/last`);
             ref.on('value', (snapshot) => {
                 const raw = snapshot.val() || {};                              
                 setLastActivity(raw);
             },    
             (error) => {
                 console.log(error);            
             });
 
             return () => ref.off();
     }, [uid, currentComputer]);  
     
     const {a, w, u} = lastActivity;
     const application = a;
     const website = w;
     const time = u;

    const {appSpent, appMode, appLimit, webLimit, webSpent, webMode} = lastActivity;
    const link = /^(f|ht)tps?:\/\//i.test(website) ? website : 'http://' + website;  
    const lastTime = time ? moment.utc(time, 'X').format("D MMM, h:mm:ss a") : '';    
       
    if (!application) return null;

    return (  
        <div>
            <h3>Latest Activity
                <div className="app-link">{lastTime}</div>                                        
            </h3>            
            <div className="app-chart" style={{paddingLeft : 40}}>   
                <div style={{marginTop : 10, display: 'flex'}}><span style={{color: '#666', marginRight: 26}}>Last seen: </span>{lastTime}</div>      
                <div style={{marginTop : 10, display: 'flex'}}><span style={{color: '#666', marginRight: 10}}>Application: </span><AppIcon key={application} name={application}/></div>
                {website&&<div  style={{color: '#666', marginTop : 10, display: 'flex'}}><span style={{marginRight: 27}}>Website: </span><WebIcon key={website} name={website}>{<a href={link} rel="noopener noreferrer" target="_blank">{website}</a>}</WebIcon></div>}
                {timeLimit('App Limit:', appLimit, appMode, appSpent)}          
                {timeLimit('Web Limit:', webLimit, webMode, webSpent)}                      
            </div>
        </div>        
    )

}

export default withUID(LastActivity);
