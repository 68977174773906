import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import { Icon, Message } from 'semantic-ui-react';
import moment from 'moment';
import AppIcon from '../UI/AppIcon';
import WebIcon from '../UI/WebIcon';
import isURL from 'validator/lib/isURL';
import withUID from './../withUID';
import { NavLink } from 'react-router-dom';
import ToolTip from '../UI/ToolTip';


const AlertsIcon = ({ name, reason }) => {
  if ((reason === 'locally') || (reason === 'onlne dashboard')) {
    return <div><Icon name="exclamation circle" color="grey" style={{marginLeft:5, marginRight:10}}/>{name}</div>
  }

  if (reason === 'Time Controls') {
    return <div><Icon name="clock outline" color="grey" style={{marginLeft:5, marginRight:10}}/>{name}</div>
  }

  const link = /^(f|ht)tps?:\/\//i.test(name) ? name : 'http://' + name;
  return isURL(name) ? (
    <WebIcon name={name}>
      <a href={link} title={link} rel="noopener noreferrer" target="_blank">
        {name} 
      </a>
    </WebIcon>
  ) : (
    <AppIcon name={name} />
  );
};

const DashboardAlerts = ({ currentComputer, uid }) => {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    if (!currentComputer) return;
    const ref = firebase.database().ref(`${uid}/${currentComputer}/reports/alerts`);    

    const getAlerts = (snapshot) => {
      const alerts = Object.values(snapshot.val() || {});
      alerts.sort((a, b) => b.time - a.time);
      setAlerts(alerts.slice(0, 6));
    };

    ref.on('value', getAlerts, (error) => console.log(error));

    return () => {
      if (ref) ref.off();
    };
  }, []);

  const items = (alerts || []).map(({ name, time, reason, title }, index) => {  
    const isToday = moment().format('YYMMDD') === moment.utc(time, 'X').format('YYMMDD');    
   
    return ( <div className="app-item" key={time + index} >            
            <div style={{width: "350px"}}><AlertsIcon name={name ? name : title} reason={reason} /></div>
            <div className="app-time">{moment.utc(time, 'X').format(isToday ? 'h:mm a' : 'D MMM')}</div>                                 
        </div>
    )}
    );

  return (
    <div>    
      <h3>Alerts<ToolTip text='Alerts show blocked activities attempted by your child.'/>
        <div className="app-link"><NavLink exact to={"/alerts"} className="link active">[ See more  ]</NavLink></div>                                
      </h3>
      <div className="app-chart" style={{marginTop: 28, height : 300}}>
            {items.length > 0 ? items : <Message style={{ marginBottom: 30 }} warning content="No activity today" />}
     </div>        
    </div>
  );
};

export default withUID(DashboardAlerts);